.playing {
  color: green;
}

button.prev,
button.next {
  width: 30%;
  height: 50px;
}

.testCss {
  color: red;
}
.ReactVirtualized__Grid {
  width: 100%;
}
.virtualList {
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: 100%;
}
.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

.MuiTabPanel-root {
  height: 1px;
}
.audioItem {
  height: 80px;
}

.playButton {
  width: 50px;
}

audio {
  width: 80%;
}

@media screen and (min-width: 800px) {
  .playButton {
    width: 150px;
    height: 30px;
  }
}

@media screen and (max-height: 1080px) {
  .virtualList {
    min-height: 600px;
  }
}

@media screen and (max-height: 800px) {
  .virtualList {
    min-height: 500px;
  }
}
@media screen and (max-height: 500px) {
  .virtualList {
    min-height: 300px;
  }
}
